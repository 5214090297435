export const aboveQuotaGrowthRateRule = (commissionData, salesRepAttributes, override = null, tiers = null) => {

    if (!Array.isArray(commissionData)) return [];

    //if (override) salesRepAttributes = override;
    const aboveQuota = commissionData.filter(
        commissionVoucherData => commissionVoucherData['rawYoy%'] >= salesRepAttributes?.quotaPercent && commissionVoucherData['cmGp'] > 0 && commissionVoucherData['yr1'] === 0 && commissionVoucherData['noProd'] === 0
    );

    const concernYr1ORNoProdCommission = commissionData.filter(
        commissionVoucherData => {
            if ((commissionVoucherData['yr1'] === 1 || commissionVoucherData['noProd'] === 1) && (commissionVoucherData['cmGp'] > 0 && commissionVoucherData['cy90'] > 0)) {
                return true;
            }
        }
    );

    const aboveQuotaData = [...aboveQuota, ...concernYr1ORNoProdCommission];


    //update commission based on override
    if (override) {
        aboveQuotaData.map((value, index, collection) => {
            const overridePercent = tiers.filter(tier => {
                const ret = parseFloat(value['rawYoy%']) >= parseFloat(tier.attainmentMin) && parseFloat(value['rawYoy%']) <= parseFloat(tier.attainmentMax);
                return ret
            })
            const overrideCommissionAmount = value.commission * ((overridePercent[0]?.attainmentPercentage || 0) / 100);
            Object.defineProperty(value, 'commission', { value: overrideCommissionAmount, writable: true })

        })
    }

    return aboveQuotaData;

};

export const underPerformingGrowthRateRule = (
    commissionVoucherData,
    salesRepAttributes,
    override = null,
    tiers = null
) => {


    const underPerformingData = commissionVoucherData.filter(commissionData => {
        return (
            commissionData['cmGp'] > 0 && commissionData['yr1'] === 0 && commissionData['noProd'] === 0 &&
            commissionData['rawYoy%'] >= salesRepAttributes?.midQuotaPercent &&
            commissionData['rawYoy%'] <= salesRepAttributes?.quotaPercent
        );
    });


    //update commission based on override
    if (override) {
        underPerformingData.map((value, index, collection) => {
            const overridePercent = tiers.filter(tier => {
                const ret = parseFloat(value['rawYoy%']) >= parseFloat(tier.attainmentMin) && parseFloat(value['rawYoy%']) <= parseFloat(tier.attainmentMax);
                return ret
            })

            const overrideCommissionAmount = value.commission * ((overridePercent[0]?.attainmentPercentage || 0) / 100);
            Object.defineProperty(value, 'commission', { value: overrideCommissionAmount, writable: true })

        })
    }

    return underPerformingData;
};

export const concernGrowthRateRule = (commissionData, salesRepAttributes, override = null, tiers = null) => {


    const currentMonthGrossProfit = commissionData.filter(
        commissionVoucherData => commissionVoucherData['cmGp'] <= 0
    );

    const concernCommission = commissionData.filter(
        commissionVoucherData =>
            (commissionVoucherData['rawYoy%'] < salesRepAttributes?.midQuotaPercent || commissionVoucherData['cmGp'] <= 0)
            && commissionVoucherData['yr1'] === 0 && commissionVoucherData['noProd'] === 0
    );

    const concernYr1ORNoProdCommission = commissionData.filter(
        commissionVoucherData => {
            if ((commissionVoucherData['yr1'] === 1 || commissionVoucherData['noProd'] === 1) && (commissionVoucherData['cy90'] <= 0 || commissionVoucherData['cmGp'] <= 0)) {
                return true;
            }

        }

    );

    const concernData = [...concernCommission, ...concernYr1ORNoProdCommission];


    //update commission based on override
    if (override) {
        concernData.map((value, index, collection) => {
            const overridePercent = tiers.filter(tier => {

                const ret = parseFloat(value['rawYoy%']) >= parseFloat(tier.attainmentMin) && parseFloat(value['rawYoy%']) <= parseFloat(tier.attainmentMax);
                return ret
            })
            const overrideCommissionAmount = value.commission * ((overridePercent[0]?.attainmentPercentage || 0) / 100);
            Object.defineProperty(value, 'commission', { value: overrideCommissionAmount, writable: true })
        })
    }

    return concernData;

};


export const unremittedGrowthRateRule = (commissionData, salesRepAttributes, override = null, tiers = null) => {



    const unremittedGrossProfit = commissionData.filter(
        commissionVoucherData => commissionVoucherData['unremGp'] > 0 && commissionVoucherData['yr1'] === 0 && commissionVoucherData['noProd'] === 0
    );

    const unremittedLessThanZeroGrossProfit = commissionData.filter(
        commissionVoucherData => commissionVoucherData['unremGp'] < 0 && commissionVoucherData['yr1'] === 0 && commissionVoucherData['noProd'] === 0
    );

    const unremittedYr1OrNoProdGrossProfit = commissionData.filter(
        commissionVoucherData => commissionVoucherData['unremGp'] > 0 && (commissionVoucherData['yr1'] === 1 || commissionVoucherData['noProd'] === 1)
    );

    const unremittedYr1OrNoProdLessThanZeroGrossProfit = commissionData.filter(
        commissionVoucherData => commissionVoucherData['unremGp'] < 0 && (commissionVoucherData['yr1'] === 1 || commissionVoucherData['noProd'] === 1)
    );

    const resultingCommissionData = [...unremittedGrossProfit, ...unremittedLessThanZeroGrossProfit, ...unremittedYr1OrNoProdLessThanZeroGrossProfit, ...unremittedYr1OrNoProdGrossProfit];


    //update commission based on override
    if (override) {
        resultingCommissionData.map((value, index, collection) => {
            const overridePercent = tiers.filter(tier => {

                const ret = parseFloat(value['adjustedRawYoy%']) >= parseFloat(tier.attainmentMin) && parseFloat(value['adjustedRawYoy%']) <= parseFloat(tier.attainmentMax);
                return ret
            })

            const overrideCommissionAmount = value.adjCommission * ((overridePercent[0]?.attainmentPercentage || 0) / 100);
            Object.defineProperty(value, 'adjCommission', { value: overrideCommissionAmount, writable: true })

        })
    }

    return resultingCommissionData;






};
