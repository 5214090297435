import insightQoreService from '../services/insightQoreService';
export const VOUCHER_BY_SALES = '@COMMISSIONVOUCHER/VOUCHER_BY_SALES';
export const VOUCHER_BY_SALES_CLEAR = '@COMMISSIONVOUCHER/VOUCHER_BY_SALES_CLEAR';
export const TIER_COMMISSION_SUMMARY =
  '@COMMISSIONVOUCHER/GET_COMM_TIER_COMMISSION_SUMMARY';
export const SELECTED_COMM_PERIOD = '@COMMISSIONVOUCHER/SELECTED_COMM_PERIOD';

export const VOUCHER_BY_SALES_PERIOD =
  '@COMMISSIONVOUCHER/GET_COMM_VOUCHER_BY_SALES_PERIOD';
export const SALES_TEAM = '@COMMISSIONVOUCHER/SALES_TEAM';
export const SALES_PERIODS = '@COMMISSIONVOUCHER/SALES_PERIODS';
export const SELECTED_SALES_TEAM_MEMBER =
  '@COMMISSIONVOUCHER/SELECTED_SALES_TEAM_MEMBER';
export const SELECTED_SALES_PERIOD = '@COMMISSIONVOUCHER/SELECTED_SALES_PERIOD';

export const MTR_SALES_SUMMARY = '@COMMISSIONVOUCHER/MTR_SALES_SUMMARY';
export const CLEAR_SALES_SUMMARY = '@COMMISSIONVOUCHER/CLEAR_SALES_SUMMARY';

export const MTR_NAVIGATATION_KEY_DATA =
  '@COMMISSIONVOUCHER/MTR_NAVIGATATION_KEY_DATA';
export const ASSOCIATED_MEMBERS = '@COMMISSIONVOUCHER/ASSOCIATED_MEMBERS';

export function setMtrNavigationData(sourceData) {
  return async dispatch => {
    dispatch({
      type: MTR_NAVIGATATION_KEY_DATA,
      payload: { sourceData },
    });
  };
}

export function clearCommissionVouchersBySalesPeriod() {
  return async dispatch => {
    dispatch({
      type: VOUCHER_BY_SALES_CLEAR,
      payload: { salesData: [] },
    });
  };
}

export function getCommissionVouchersBySalesPerson(period, override = null) {
  return async dispatch => {
    const { salesPerson, month, year } = period;
    let tiers = [];

    if (override) {
      tiers = await insightQoreService.getSalesAgentCommissionTiersById(override.parentSalesTeamId);
    }

    //JSON.stringify(override)
    const salesData = override ? await insightQoreService.getCommissionVouchersBySalesPerson(
      salesPerson,
      month,
      year,
      null
    ) : await insightQoreService.getCommissionVouchersBySalesPerson(
      salesPerson,
      month,
      year,
      null
    );

    dispatch({
      type: VOUCHER_BY_SALES,
      payload: { salesData, period, override, tiers },
    });
  };
}

export function getCommissionVouchersDataWithSalesPeriodsAndSalesMembers(
  view,
  teamMember,
  isBdeUser = false
) {
  return async dispatch => {

    let result = await insightQoreService.getCommissionVouchersDataWithSalesPeriodsAndSalesMembers(
      view,
      teamMember
    );

    let associates =
      (await insightQoreService.getSalesTeamAttributes()) || [];

    if (associates && associates.length === 0) {
      associates = []
    }
    let filterAssociated = [];
    let filteredAssociated = [];
    let bdeFilterAssociated = [];
    let bdeFilteredAssociated = [];
    let uniqueSalesRep = [];

    let salesReps = salesReps => {
      let unique_values = salesReps
        .filter(item => item.attributes.isEnabled)
        .map(item => item?.salesPerson)
        .filter(
          (value, index, current_value) => {
            return current_value.indexOf(value) === index
          }

        );
      return unique_values;
    };

    uniqueSalesRep = salesReps(result);

    if (associates && Array.isArray(associates)) {

      filterAssociated = associates
        ?.map(item => item)
        .filter(
          (value, index, current_value) =>
            value.repRoleCode === 'ASR' && value.isEnabled && view === 'CV'
        );

      filteredAssociated = filterAssociated
        ?.map(item => item.salesPersonName)
        .filter(
          (value, index, current_value) =>
            current_value.indexOf(value) === index
        );


      bdeFilterAssociated = associates
        ?.map(item => item)
        .filter(
          (value, index, current_value) =>
            value.repRoleCode === 'BDE' && value.isEnabled && view === 'MTR'
        );


      if (teamMember && isBdeUser) {
        const teamMemberAttributes = await insightQoreService.getSalesTeamMemeberAttributeByFullName(
          teamMember
        );
        if (teamMemberAttributes) {
          let associatedTeamMembers = await insightQoreService.getAssociatedTeamMemberById(
            teamMemberAttributes.salesTeamId
          );

          const filteredAssociated = associatedTeamMembers
            ?.map(item => item.salesPersonName)
            .filter(
              (value, index, current_value) =>
                current_value.indexOf(value) === index
            );

          bdeFilteredAssociated.push(teamMember);
          for (let index = 0; index < filteredAssociated.length; index++) {
            const element = filteredAssociated[index];
            bdeFilteredAssociated.push(element);
          }

          bdeFilteredAssociated.concat(filteredAssociated);

          //get and filter sales member / period
          result = await insightQoreService.getCommissionVouchersDataWithSalesPeriodsAndSalesMembers(
            view,
            null
          );

          uniqueSalesRep = [];
        }
      }
    }


    dispatch({
      type: VOUCHER_BY_SALES_PERIOD,
      payload: {
        salesCommissionData: result,
        uniqueSalesRep,
        filteredAssociated,
        bdeFilteredAssociated,
      },
    });
  };
}

export function getTieredCommissionSummary(salesPerson, atQuota, yoY) {
  return async dispatch => {
    const result = await insightQoreService.getGetTieredCommissionSummaryBySalesRep(
      salesPerson,
      atQuota,
      yoY
    );

    dispatch({
      type: TIER_COMMISSION_SUMMARY,
      payload: { ...result },
    });
  };
}

export function clearSalesSummaryData() {
  return async dispatch => {
    dispatch({
      type: CLEAR_SALES_SUMMARY,
      payload: { result: [], grossProfit80Data: [], grossProfit20Data: [] },
    });
  };
}
export function getGrossProfitData(salesPerson, month, year) {
  return async dispatch => {
    const result = await insightQoreService.getMtrRevenue(
      salesPerson,
      month,
      year
    );

    let grossProfit80Data = result?.detail
      ?.map(item => item)
      .filter((value, index, current_value) => value.isEighty === 1);

    let grossProfit20Data = result?.detail
      ?.map(item => item)
      .filter((value, index, current_value) => value.isEighty === 0);

    dispatch({
      type: MTR_SALES_SUMMARY,
      payload: { result, grossProfit80Data, grossProfit20Data },
    });
  };
}

export function setSelectedSalesTeamMember(selectedTeamMember) {
  return async dispatch => {
    dispatch({
      type: SELECTED_SALES_TEAM_MEMBER,
      payload: selectedTeamMember,
    });
  };
}

export function setSelectedPeriod(selectedPeriod) {
  localStorage.setItem('selectedSalesPeriod', JSON.stringify(selectedPeriod));
  return async dispatch => {
    dispatch({
      type: SELECTED_SALES_PERIOD,
      payload: selectedPeriod,
    });
  };
}

export function setSalesPeriodList(salesPeriods) {
  return async dispatch => {
    dispatch({
      type: SALES_PERIODS,
      payload: salesPeriods,
    });
  };
}

export function getAssociatedMembers(userFullName) {
  return async dispatch => {
    const members = await insightQoreService.getAssociatedMembers(userFullName);

    dispatch({
      type: ASSOCIATED_MEMBERS,
      payload: { members },
    });
  };
}
